:root {
  --white: #fff;
  --black: #000;

  /* Primary colors*/
  --primary-1: #2b8379;
  --primary-2: #19a596;
  --primary-3: #7fd1c7;
  --primary-4: #7fd1c7;
  --primary-5: #d0f3ef;

  /* Neutral colors */
  --neutral-1: #2c2c2c;
  --neutral-2: #434343;
  --neutral-3: #94868b;
  --neutral-4: #d9cfd3;
  --neutral-5: #f2ecee;

  /* Accent-blue colors */
  --accent-1: #970e3d;
  --accent-2: #c43464;
  --accent-3: #de7699;
  --accent-4: #e6c3ce;
  --accent-5: #fff2f7;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
  border: 1px solid lightgray;
}

form {
  background-color: #f7f7f7;
  padding: 20px;
}

label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

input[type="text"],
input[type="date"],
input[type="number"],
input[type="time"] {
  display: block;
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button[type="submit"] {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* NAVBAR */
.navbar {
  background-color: white;
  padding: 10px;
  box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.07),
    0px -3px 7px 1px rgba(0, 0, 0, 0.07);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-brand {
  font-size: 30px;
  font-weight: 450;
  text-decoration: none;
  padding-left: 20px;
  color: initial;
}

@media (min-height: 900px) {
  .nav-brand {
    font-size: 40px;
    font-weight: 450;
    text-decoration: none;
    padding-left: 20px;
    color: initial;
  }
}

.nav-brand:hover {
  text-decoration: none;
}
.nav-brand:active {
  color: var(--primary-1);
}

.navbar ul {
  list-style-type: none;
  overflow: hidden;
  padding-right: 20px;
  text-align: center;
}

.navbar li {
  display: inline-block;
}

.nav-item a {
  text-decoration: none;
  font-weight: normal;
  color: var(--primary-1);
  display: inline-block;
  text-align: center;
  padding: 13px 10px;
  min-width: 35px;
}

@media (min-height: 900px) {
  .nav-item a {
    text-decoration: none;
    font-weight: normal;
    color: var(--primary-1);
    display: inline-block;
    text-align: center;
    padding: 13px 20px;
    min-width: 75px;
  }
}

.nav-item:hover a {
  text-decoration: none;
  color: var(--primary-2);
}

.nav-item a.active {
  font-weight: bold;
}

.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signup-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

select {
  font-size: 16px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 250px;
}

.correlation{
  margin: 20px 0;
  font-size: 1.2rem;
  font-weight: bold;
}

optgroup {
  font-weight: bold;
  color: #666;
}

option {
  padding: 5px;
}

option:disabled {
  color: #999;
}

option:checked {
  background-color: #f1f1f1;
  font-weight: bold;
  color: #333;
}

.correlation-instruction {
  color: gray;
  font-style: italic;
}


.integration-table {
  border: none;
  border-spacing: 10px;
  border-collapse: separate;
}

.integration-table th,
.integration-table td {
  padding: 10px;
}

.chart-container {
  width: 400px;
  height: 500px;
}

@media (min-width: 800px) {
  .chart-container {
    width: 800px;
    height: 600px;
  }
}
.mini-chart {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.bar {
  height: 20px;
}

.bar.positive {
  background-color: green;
  margin-left: 5px;
}

.bar.negative {
  background-color: red;
  margin-right: 5px;
}

.chart-value {
  text-align: center;
  font-weight: bold;
}

.container {
  max-width: 800px;
  margin: 5px;
}

.form-group {
  margin-bottom: 10px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="date"],
input[type="number"],
input[type="text"],
input[type="time"],
input[type="checkbox"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/*button {*/
/*  padding: 10px 15px;*/
/*  background-color: #007bff;*/
/*  color: #fff;*/
/*  border: none;*/
/*  border-radius: 4px;*/
/*  cursor: pointer;*/
/*}*/

button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
}

.no-habits-message {
  margin-top: 20px;
  font-weight: bold;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

li::before {
  content: "";
  color: #007bff; /* or any color you prefer */
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

li:hover {
  color: #808080;
}

.form-label {
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;
}

.checkbox-input {
  margin-left: 10px;
}
.label-text {
  padding-right: 10px;
}

/* Style for the container of the checklist */
.checklist-container {
  max-height: 300px; /* Set a maximum height to limit the checklist height */
  overflow-y: auto; /* Enable scrolling for long lists */
  margin-left: 20px;
}

/* Style for the checkbox label */
.checklist-label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

/* Style for the checkbox input */
.checklist-checkbox {
  margin-right: 8px;
}

/* Style for the checkbox tick */
.checklist-checkbox:checked + .checklist-custom-checkbox::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #333; /* Customize the tick color */
}

/* Style for the custom checkbox appearance */
.checklist-custom-checkbox {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #333; /* Customize the border color */
  border-radius: 4px;
  transition: background-color 0.3s, border-color 0.3s;
}

/* Style for the hover effect */
.checklist-custom-checkbox:hover {
  background-color: #f1f1f1; /* Customize the hover background color */
  border-color: #888; /* Customize the hover border color */
}

/* Style for the focus effect */
.checklist-custom-checkbox:focus {
  outline: none;
  box-shadow: 0 0 3px 3px rgba(0, 123, 255, 0.3); /* Customize the focus box shadow */
}

